.banner-height{
  min-height: 60vh
}

.logo_text{
  /* color: #e03c33 !important;
  top: -4px;
  position: relative; */
  img{
    width: 150px;
  }
}

.cause_heading{
  a{
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    /* height: calc(2em* 1.2); */
    line-height: 1.2em;
  }
}

.footer_area_about{
  img{
    width: 200px;
  }
}